<template>
  <div
    v-if="page"
    :class="$style.root"
  >
    <LayoutIntroductionComponent
      v-if="page?.richText"
      :introduction="page?.richText"
      :class="$style.introduction"
    />

    <BaseCarouselComponent
      :class="$style.slides"
      type="fade"
      :rewind="true"
      @setSlideIndex="slideIndex = $event"
    >
      <template #top="{ goToSlide }">
        <client-only v-if="slides?.length > 1">
          <nav :class="$style.nav">
            <BaseCarouselGoComponent :class="$style.button" :go-to-slide="goToSlide" index="<">
              <CoreButtonsArrowComponent />
            </BaseCarouselGoComponent>
            <BaseCarouselGoComponent :class="$style.button" :go-to-slide="goToSlide" index=">">
              <CoreButtonsArrowComponent dir='next' />
            </BaseCarouselGoComponent>
          </nav>
        </client-only>
      </template>
      <!-- NOTE should only show once all are loaded -->
      <BaseCarouselSlideComponent
        v-for="(slide, index) in slides"
        :key="slide?.value?.id"
      >
        <PagesIndexSlideComponent
          :data="slide"
          :data-last-slide="lastSlideIndex === index ? '' : null"
          :data-full="slideIndexHover === slide?.value?.id ? '' : null"
        />
      </BaseCarouselSlideComponent>
    </BaseCarouselComponent>

    <PagesIndexSlideInfoComponent
      :data="currentSlide"
      :key="currentSlide?.id"
      :slideIndex="(slideIndex + 1)"
      :slideLength="slides?.length"
      @setSlideId="slideIndexHover = $event"
    />

    <SharedNewsletterComponent :class="$style.newsletter" />
</div>
</template>

<script lang="ts" setup>
const mainStore = useMainStore()

import { uniq } from 'lodash-es'

const props = defineProps({
  data: Object
})

const page = baseStrapiGetAttributes(props.data)

const slides = page?.slides?.map((i) => {
  const data = baseFindValAndKeyByKeyInObject(i, 'data')
  return data
}).filter((i) => {
  return i?.value?.attributes?.coverImage?.data
})

const slideTypes = uniq(slides?.map((i) => i?.key))
mainStore.coreBackgroundSvgSlideTypes = slideTypes

const slideIndexHover = ref(null)
const slideIndex = ref(0)
const lastSlideIndex = computed(() => {
  const index = slideIndex.value === 0 ? slides?.length - 1 : slideIndex.value - 1
  return index
})

const currentSlide = computed(() => {
  return slides?.[slideIndex.value]
})
</script>

<style module>
.root {
}

.introduction {
  padding-bottom: 0;
}

.slides {
  padding: var(--padding--container);
  padding-bottom: 0;
  position: relative;

  --base--image--background-color: var(--color--white);
}

.slides :global(.splide__slide:not(.is-active)) {
  opacity: 1 !important;
  pointer-events: none;
}

.nav {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: calc(var(--unit--default) / 2);
  padding-right: calc(var(--unit--default) / 2);

  pointer-events: none;
}

.nav * {
  pointer-events: auto;
}

.button {
  composes: reset-button from global;
  width: var(--size--button);
  height: var(--size--button);
}

.newsletter {
  composes: container from global;
  margin-top: calc(var(--unit--default) * 3);
}

</style>
